@import './src/global.scss';
@import './index.scss';

$brand: #4e5d9d;

:root {
	--brand100: #{$brand};
	--brand90: #{rgba($brand, 0.9)};
	--brand80: #{rgba($brand, 0.8)};
	--brand70: #{rgba($brand, 0.7)};
	--brand60: #{rgba($brand, 0.6)};
	--brand50: #{rgba($brand, 0.5)};
	--brand40: #{rgba($brand, 0.4)};
	--brand30: #{rgba($brand, 0.3)};
	--brand20: #{rgba($brand, 0.2)};
	--brand10: #{rgba($brand, 0.1)};
}
